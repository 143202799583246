// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Template styles
@import '../template/assets/scss/variables.scss';
@import '../template/assets/scss/style.scss';

// Quill themes
@import '~quill/dist/quill.snow.css';

// Select2
@import "~select2/src/scss/core";

// Select2 Bootstrap 4 theme
@import "~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4";

@import './_variables';
@import './style.scss';
