body.home {
  background-color: #fff;
}

header {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
}

header .logo {
  max-width: 395px;
  width: 100%;
}

header,
header .btn {
  font-size: 1rem;
}

.w-0 {
  width: 0;
}

.line-clamp {
  overflow: hidden;
  max-height: 80px;
}

.icon-book {
  width: 25px;
  transition: filter .15s ease-in-out;
}

a:hover .icon-book,
a:focus .icon-book {
  filter: invert(1) brightness(10);
}

.locales > a {
  text-transform: uppercase;
}

main {
  margin: 0;
}

.color-text,
.hover-color-text:hover,
.hover-color-text:focus {
  color: $color-text;
}

.color-header,
.hover-color-header:hover,
.hover-color-header:focus,
header .dropdown-toggle,
a svg {
  color: $color-header;
}

a:hover,
.color-link,
.hover-color-link:hover,
.hover-color-link:focus,
header .dropdown-toggle:hover,
a:hover svg {
  color: $color-link;
}

.color-muted,
.hover-color-muted:hover,
.hover-color-muted:focus {
  color: $color-muted;
}

.hover-decoration-underline:hover {
  text-decoration: underline;
}

.welcome {
  font-size: 1rem !important;
}

.welcome .color-text {
  line-height: 1.6;
}

.welcome .btn {
  font-size: 1.2em;
}

.welcome .color-text {
  font-size: 1.5em;
}

.welcome-title {
  font-size: 2.5em;
  line-height: 1.3em;
  font-weight: 600;
}

.welcome .form-text {
  font-size: 1em;
}

.bg-color-link,
.hover-bg-color-link:hover,
.hover-bg-color-link:focus {
  background-color: $color-link;
}

.border-color-link,
.hover-border-color-link:hover
.hover-border-color-link:focus {
  border-color: $color-link;
}

.bg-color-white,
.hover-bg-color-white:hover,
.hover-bg-color-white:focus {
  background-color: #fff;
}

.color-white,
.hover-color-white:hover,
.hover-color-white:focus {
  color: #fff;
}

.brochure::before {
  content: '';
  position: absolute;
  padding: 100%;
  border-radius: 50%;
  background: linear-gradient(159.86deg, #FAFCFF 17.76%, #D7E9FF 86.58%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotate-pulse 5s ease-in-out infinite;
  z-index: -1;
}

.brochure img {
  width: 100%;
  max-width: 317px;
  transform: rotate(30deg);
  filter: drop-shadow(40px 40px 30px rgba(0,0,0,0.3));
  transition: transform .3s ease-in;
  image-rendering: auto;
}

.brochure img:hover {
  transform: rotate(20deg) scale(1.1);
  transition: transform .5s ease-out;
}

footer {
  background-color: #FBFBFB;
}

footer .list {
  list-style-image: url(../images/icon-pdf.svg);
}

.copyright {
  color: #BDC4CE;
  border-top: 1px solid #D4E1E9;
}

.certificate {
  position: relative;
  width: 968px;
  font-family: 'DejaVu Sans', Arial, Helvetica, sans-serif;
}

.certificate img {
  max-width: 100%;
  height: auto;
}

.cert-body {
  position: absolute;
  top: 130px;
  right: 0;
  width: 570px;
  padding: 0 30px;
}

.cert-footer {
  position: absolute;
  bottom: 130px;
  right: 0;
  width: 200px;
  padding: 0 27px;
}

.cert-body h1 {
  margin-bottom: 5px;
  font-size: 35px;
  font-weight: bold;
  line-height: 0.57;
  text-transform: uppercase;
  color: #2e4a66;
}

.cert-body h2 {
  margin-bottom: 0;
  font-size: 29px;
  font-weight: bold;
  line-height: 1;
  color: #384360;
}

.cert-body h2:nth-of-type(2) {
  margin-bottom: 20px;
}

.cert-body span {
  display: block;
  font-size: 21px;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
  color: #384360;
}

.cert-body span:nth-of-type(1) {
  margin-bottom: 30px;
}

.cert-footer span {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: .9;
  color: #0075b1;
  text-align: right;
}

.cert-footer span:nth-of-type(2) {
  font-weight: normal;
}

.table-sort svg {
  width: .5rem;
  height: auto;
}

.list-group [data-toggle='collapse'] {
  font-weight: 700;
}

.list-group [data-toggle='collapse'] ~ .list-group .list-group-item {
  padding-right: 0;
}

.list-group-counter {
  flex: 100%;
  counter-reset: list1;
}

.list-group-counter .list-group-item {
  display: flex;
  flex-wrap: wrap;
}

.list-group-counter .list-group-item::before {
  counter-increment: list1;
  content: counters(list1, '.', upper-roman);
  padding: .5rem 1rem .5rem 0;
}

.list-group-counter .list-group-item > a {
  flex: 1;
}

[data-toggle='collapse'] .bi-chevron-down {
  transition: transform .35s ease;
}

[aria-expanded='true'] .bi-chevron-down {
  transform: rotate(180deg);
}

.ql-toolbar,
.editor {
  border-radius: 0.25rem;
}

.ql-toolbar {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.editor {
  height: 350px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.ql-editor {
  position: static;
  white-space: initial;
}

.ql-editor-content {
  padding: 0;
}

.ql-editor-content a {
  color: var(--primarycolor);
}

.ql-container span[id]::before {
  content: "# ";
}

.ql-editor-content blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.ql-editor li {
  margin-bottom: 1em;
  position: relative;
}

.ql-editor li::before {
  width: auto;
  position: absolute;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  right: 100%;
}

.ql-editor li.ql-direction-rtl::before {
  left: 100%;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  margin-left: 1.5em;
  padding: 0;
}

.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  margin-right: 1.5em;
  padding: 0;
}

.ql-editor .ql-indent-1,
.ql-editor li.ql-indent-1,
.ql-editor .ql-indent-2,
.ql-editor li.ql-indent-2,
.ql-editor .ql-indent-3,
.ql-editor li.ql-indent-3,
.ql-editor .ql-indent-4,
.ql-editor li.ql-indent-4,
.ql-editor .ql-indent-5,
.ql-editor li.ql-indent-5,
.ql-editor .ql-indent-6,
.ql-editor li.ql-indent-6,
.ql-editor .ql-indent-7,
.ql-editor li.ql-indent-7,
.ql-editor .ql-indent-8,
.ql-editor li.ql-indent-8,
.ql-editor .ql-indent-9,
.ql-editor li.ql-indent-9 {
  padding: 0 !important;
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  margin-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  margin-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  margin-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  margin-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  margin-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  margin-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  margin-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  margin-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  margin-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  margin-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  margin-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  margin-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  margin-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  margin-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  margin-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  margin-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  margin-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  margin-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  margin-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  margin-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  margin-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  margin-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  margin-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  margin-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  margin-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  margin-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  margin-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  margin-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  margin-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  margin-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  margin-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  margin-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  margin-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  margin-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  margin-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  margin-right: 28.5em;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '. ';
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '.' 
           counter(list-2, decimal) '. ';
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '.' 
           counter(list-2, decimal) '.' 
           counter(list-3, decimal) '. ';
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '.' 
           counter(list-2, decimal) '.'
           counter(list-3, decimal) '.' 
           counter(list-4, decimal) '. ';
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '.' 
           counter(list-2, decimal) '.'
           counter(list-3, decimal) '.' 
           counter(list-4, decimal) '.' 
           counter(list-5, decimal) '. ';
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '.' 
           counter(list-2, decimal) '.'
           counter(list-3, decimal) '.' 
           counter(list-4, decimal) '.' 
           counter(list-5, decimal) '.' 
           counter(list-6, decimal) '. ';
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '.' 
           counter(list-2, decimal) '.'
           counter(list-3, decimal) '.' 
           counter(list-4, decimal) '.' 
           counter(list-5, decimal) '.' 
           counter(list-6, decimal) '.' 
           counter(list-7, decimal) '. ';
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '.' 
           counter(list-2, decimal) '.'
           counter(list-3, decimal) '.' 
           counter(list-4, decimal) '.' 
           counter(list-5, decimal) '.' 
           counter(list-6, decimal) '.' 
           counter(list-7, decimal) '.' 
           counter(list-8, decimal) '. ';
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-0, decimal) '.' 
           counter(list-1, decimal) '.' 
           counter(list-2, decimal) '.'
           counter(list-3, decimal) '.' 
           counter(list-4, decimal) '.' 
           counter(list-5, decimal) '.' 
           counter(list-6, decimal) '.' 
           counter(list-7, decimal) '.' 
           counter(list-8, decimal) '.' 
           counter(list-9, decimal) '. ';
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  border-color: #ced4da;;
  box-shadow: none;
}

.select2-container--bootstrap4 .select2-results__option--highlighted, 
.select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected=true] {
  color: #fff;
  background-color: $color-link;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #212529;
  background-color: rgba(0,0,0,.075);
}

.list-group input:checked + .list-group-item-action,
.list-group input:checked + .list-group-item-action:focus,
.list-group input:checked + .list-group-item-action:hover {
  color: #fff;
  background-color: $color-link;
}

.list-group .badge {
  transition: none;
}

.list-group input:checked + .list-group-item-action .badge {
  background-color: #fff;
}

@keyframes rotate-pulse {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) rotate(180deg) scale(1.1);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }
}

@media (max-width: 991px) {
  header,
  header .btn {
    font-size: .9rem;
  }

  .welcome {
    font-size: .8rem;
  }
}

@media (max-width: 767px) {
  header .logo{
    max-width: 210px;
  }
}
