/* Common Css */
body{
    font-family: $body-font;
    font-size: 12px;
    background: var(--bodybackground);
    color:var(--bodycolor);
    font-weight: 500;
    letter-spacing: 0.3px;
}

.vh-100 {
    min-height: 100vh;
}
.body-color, .form-control, .form-control:focus{
    color:var(--bodycolor);
}
hr{
    border-top: 1px solid var(--bordercolor);
}
.border, .theme-border
{
    border-color: var(--bordercolor) !important;
}
.border-white
{
    border-color: #fff !important;
}
main
{
    margin-top: 65px;
    top:0px;
    margin-left: 250px;  

    @media (max-width: 991px){
        margin-left:0px;
        width: 100%;
    }
}
a{
    color:var(--bodycolor);
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}
.theme-background
{
    background-color: var(--mainbackground);
}
.z-index-1
{
    z-index: 1;
}
.z-index-0
{
    z-index: 0;
}
.pointer-event-none
{
    pointer-events: none;
}
.color-primary, .text-primary, .btn-link
{
    color:var(--primarycolor) !important;
}
.fill-color
{
    fill:var(--primarycolor);
}
.fill-color-rgba
{
    fill:rgba(var(--primarycolor),0.7);
}
.hide-sidebar 
{
    main, .site-footer
    {
        margin-left: 0px; 
    }

}
.btn-outline-primary
{
    color:var(--primarycolor) !important;  
    border-color:var(--primarycolor);
    &:hover, &:active, &:focus, &.dropdown-toggle { 
        &:hover, &:active, &:focus {
            box-shadow: none !important;
            border-color:var(--primarycolor) !important;
            background: var(--primarycolor)!important;
            color:#fff !important;
        }
    }
}
.bg-primary, .round-button
{
    background:var(--primarycolor) !important;
}
.bg-primary-light
{
    position: relative;
    &:before{
        content: "";
        background: var(--primarycolor);
        opacity: 0.1;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .card-body{
        z-index: 1;
    }
}
.bg-light
{
    background: #f8f9fa !important;
}
.form-control, .form-control:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus,
.btn-primary.focus, .btn-primary:focus
{
    outline: none;
    box-shadow: none;
}
.btn-primary, .btn-default, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle,
.btn-primary.focus, .btn-primary:focus,
.btn-primary.disabled, .btn-primary:disabled,
.btn-primary.disabled:hover, .btn-primary:disabled:hover
{
    background-color:var(--primarycolor) !important; 
    border-color:var(--primarycolor);
}
a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover{
    background-color:var(--primarycolor)!important; 

}

.btn-default
{
    color:#fff;   
}
.btn
{
    font-size: inherit;
    font-weight: 500;
}
.round-button
{
    width:30px;
    height: 30px;
    color:#fff;
    display: inline-block;
    border-radius: 30px;
    line-height: 30px;
    text-align: center;
    &:hover{
        color:#fff;
    }
}
.show>.btn-secondary.dropdown-toggle,
.nav-pills .nav-link.active, .nav-pills .show>.nav-link
{
    background-color:var(--primarycolor); 
    border-color:var(--primarycolor);
}

.btn-primary{   

    &:hover{
        -webkit-box-shadow: 0 8px 25px -8px rgba( #000,1);
        box-shadow: 0 8px 25px -8px rgba(#000,1);
    }
}
.font-w-600
{
    font-weight: 600;
}
.font-w-500
{
    font-weight: 500;
}
.tx-s-12
{
    font-size: 12px;
}
.font-15
{
    font-size: 15px;
}
.line-h-1
{
    line-height: 1;
}
.transaction-date
{
    min-width: 40px;
}
.bg-secondary
{
    background: var(--secondary) !important;
}
.bg-success
{
    background: var(--success)!important;
}
.bg-info
{
    background: var(--info)!important;
}
.bg-warning
{
    background: var(--warning)!important;
}
.bg-danger
{
    background: var(--danger)!important;
}
.bg-dark
{
    background: var(--dark)!important;
}


.text-secondary
{
    color: var(--secondary)!important;
}
.text-success
{
    color: var(--success)!important;
}
.text-info
{
    color: var(--info)!important;
}
.text-warning
{
    color: var(--warning)!important;
}
.text-danger
{
    color: var(--danger)!important;
}
.text-dark
{
    color: var(--dark)!important;
}

.search-bar-menu
{
    line-height: 31px;
    font-size: 20px;
    border-left: 1px solid var(--bordercolor);
    padding: 0px 10px;
    margin-top: 3px;
    &.active
    {
        color:var(--success);
    }

}
.breadcrumb-item.active, .breadcrumb-item+.breadcrumb-item::before{
    color:var(--bodycolor);
}
/************* Circle *************/
.circle-50
{
    width: 50px;
    height: 50px;
    line-height: 55px;
    border-radius: 50%;
    text-align: center;
}
.circle-40
{
    width: 40px;
    height: 40px;
    line-height: 38px;
    border-radius: 50%;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}


/************* Badge **************/
.badge {   
    line-height: 1.4;
    padding: 3px 6px;
    font-size: 11px;
    font-weight: 400;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}
.badge-primary
{
    background: var(--primarycolor);   
}

.badge-secondary 
{
    background-color: var(--secondary );   
}
.badge-success 
{
    background-color: var(--success );   
}
.badge-info
{
    background-color: var(--info);   
}
.badge-warning
{
    background-color: var(--warning);   
}
.badge-dark
{
    background-color: var(--dark);   
}
.badge-danger
{
    background-color: var(--danger);   
}

.outline-badge-primary
{
    border:1px solid var(--primarycolor);
    color:var(--primarycolor);
    position: relative;
    overflow:hidden;
    &>*{
        z-index: 1;
    }
    &:after{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--primarycolor);
        opacity: 0.2;
        left: 0px;
        top: 0px;
        z-index: 0;
    }
}
.outline-badge-secondary
{
    border:1px solid var(--secondary);
    color:var(--secondary);
    position: relative;
    overflow:hidden;
    &:after{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--secondary);
        opacity: 0.2;
        left: 0px;
        top: 0px;
    }
}
.outline-badge-success
{
    border:1px solid var(--success);
    color:var(--success);
    position: relative;
    overflow:hidden;
    &:after{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--success);
        opacity: 0.2;
        left: 0px;
        top: 0px;
    }
}
.outline-badge-info
{
    border:1px solid var(--info);
    color:var(--info);
    position: relative;
    overflow:hidden;
    &:after{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--info);
        opacity: 0.2;
        left: 0px;
        top: 0px;
    }
}
.outline-badge-warning
{
    border:1px solid var(--warning);
    color:var(--warning);
    position: relative;
    overflow:hidden;
    &:after{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--warning);
        opacity: 0.2;
        left: 0px;
        top: 0px;
    }
}
.outline-badge-danger
{
    border:1px solid var(--danger);
    color:var(--danger);
    position: relative;
    overflow:hidden;
    &:after{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--danger);
        opacity: 0.2;
        left: 0px;
        top: 0px;
    }
}
.outline-badge-dark
{
    border:1px solid var(--dark);
    color:var(--dark);
    position: relative;
    overflow:hidden;
    &:after{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--dark);
        opacity: 0.2;
        left: 0px;
        top: 0px;
    }
}

/********** Animation Style *************/
.zoom
{
    transition: transform 300ms ease;
    &:hover{
        transform: scale(1.02);
        -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
        -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
        box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    }
}

/* List Group */
.list-group-item
{
    &.active
    {
        background-color:var(--primarycolor); 
        border-color:var(--primarycolor);
    }
}
/* Border Color */

.border-left, .border-right, .border-top, .border-bottom
{  
    border-color:var(--bordercolor) !important;
}
.border-primary
{  
    border-color:var(--primarycolor) !important;
}
.border-secondary
{  
    border-color:var(--secondary) !important;
}
.border-warning
{  
    border-color:var(--warning) !important;
}
.border-info
{  
    border-color:var(--info) !important;
}
.border-success
{  
    border-color:var(--success) !important;
}
.border-dark
{  
    border-color:var(--dark) !important;
}
.border-danger
{  
    border-color:var(--danger) !important;
}
.border-w-5
{
    border-width: 5px !important;
}


/* Scroll bar color */
body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/* Card Header */

.card-liner-icon
{
    font-size: 25px;
    line-height: 31px;
}
.card-liner-title
{
    font-size: 20px;   
}
.card-liner-subtitle
{
    font-size: 13px;   
    margin-bottom: 0px;
}
.card-liner-absolute-icon
{
    position: absolute;
    right: 10px;
    top:10px;
}
.card-liner-small-tip
{
    border-radius: 20px;
    padding: 2px 7px;
    font-size: 11px;
}
.card-liner-content
{
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid var(--bordercolor);
}
.card
{
    border: 1px solid var(--bordercolor);
    border-radius:5px;
    background:var(--mainbackground);   
    .card-body
    {
        padding: 15px;
    }
    .card-header
    {
        background-color:transparent;
        border-color: var(--bordercolor);
        padding: 15px;
        h4{
            line-height: 1;  
            font-size: 1rem;
        }    
        .card-title
        {
            margin-bottom: 0px;
        }
    }
    .card-content
    {
        z-index: 1;
    }
}

/* Time Garph */
.morris-hover.morris-default-style
{
    background:var(--mainbackground);
    color:var(--bodycolor);
    border: solid 2px var(--bordercolor);;
}

/* Twitter Section */
.twitter-gradient
{
    background: rgb(0,172,237);
    background: linear-gradient(190deg, rgba(0,172,237,1) 0%, rgba(0,129,206,1) 100%);
    color:#fff;
    font-size: 18px;

    .love
    {
        font-size: 13px;
        background: #003952;
        border-radius: 20px;
        -webkit-box-shadow: 0px 9px 16px -8px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 9px 16px -8px rgba(0,0,0,0.75);
        box-shadow: 0px 9px 16px -8px rgba(0,0,0,0.75);
    }
}
#week_statistics, #month_statistics, #year_statistics
{
    height: 240px;
}

/* Bootstrap Nav Tabs */
.nav-tabs
{
    border:none;
    color:var(--bodycolor);
    .nav-link{
        border:none;
        color:var(--bodycolor);
        padding: .5rem 0.7rem;
    }

}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--primarycolor);
    background-color: transparent;
    border-bottom:2px solid var(--primarycolor);
}
.tab-content>.active{opacity:1;}

.nav-pills .nav-link {
    @media (max-width: 450px){
        font-size: 12px;
        padding: .5rem 0.5rem;
    }
}
/* To do List */
.tasks
{
    padding: 0px;
    margin: 0px;
    .task
    {
        padding: 0px;
        list-style: none;
        border-width: 3px !important;
        border-bottom:1px solid var(--bordercolor) !important;
        &.border-0
        {
            border-bottom:none !important; 
        }
        a {
            color:var(--bodycolor);
        }
    }



}
/* Checkbox Radio Button */
.chkbox {          
    position: relative;
    padding-left: 35px;   
    cursor: pointer;            
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index:1;
    line-height: 17px;
    margin-bottom: 0px;   
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border:2px solid var(--bordercolor);
        border-radius: 3px;
        &:after {
            content: "";
            position: absolute;
            display: none;       
            left: 6px;
            top: 1px;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

    }
    &.smallchk{
        padding-left: 19px;  
        line-height: 17px;
        .checkmark{
            height: 14px;
            width: 14px;  
            top: calc(50% - 8px);
            border-radius: 0px;
            &:after{
                left: 3px;
                top: -1px;
                width: 5px;
                height: 10px;
            }      
        }

    }
    input[type=radio] ~ .checkmark {

        border-radius: 25px;
    }

    &:hover input[type=radio] ~ .checkmark {

        border-radius: 25px;
    }
    input:checked ~ .checkmark {
        background-color: var(--primarycolor);
        border-color: var(--primarycolor);

    }
    input:checked + b,  input:checked + img + img + span, input:checked + img + span, input:checked + span, input:checked + p{
        text-decoration: line-through;

    }
    input[type=radio]:checked ~ .checkmark {

        border-radius: 25px;
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
    input[type=radio]:checked ~ .checkmark:after {
        top:-2px;
        left:-2px;
        border:none;
        width:20px;
        height: 20px;
        border-radius: 25px;
        background: var(--primarycolor);

    }
}


/* Activity List */
.absolute-circle
{
    width:50px;
    height: 50px;
    position: absolute;   
    border-radius: 50px;
    text-align: center;
    line-height: 53px;
    color:#fff;
    font-size:20px;
    &.dropbox
    {
        background: #018f35;  
    }
    &.galleries
    {
        background: #8bc3ea;
    }
    &.comments
    {
        background: #f28e01;
    }
}
.ml-35
{
    margin-left: 35px;
}
.activities
{
    padding: 0px;
    margin: 0px;
    .activity
    {
        padding: 0px;
        list-style: none;
        border-width: 2px !important;
        position:relative;
        border-color:var(--bordercolor) !important;
        margin-left: 62px;
        padding-left: 40px;      
        .activity-absolute
        {

            position: absolute;
            left:-62px;
            top:0px;
        }
    }
}

/* Info Card */
.background-image-maker {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: #1b1b1b;
}
.holder-image img {
    opacity:0;
}
.info-card
{
    position: relative;
    overflow: hidden;
    .date
    {
        position: absolute;
        top:10px;
        left:10px;
        font-size: 30px;
        background: #ff880d;
        width: 60px;
        text-align: center;
        border-radius: 5px;
        padding:10px;
        line-height: 30px;
        span
        {
            display: block;
            font-size: 17px;
        }
    }
    .like
    {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 13px;
        background: rgba(#000,.6);
        width: 124px;
        color: #fff;
        border-radius: 10px;
        padding: 2px 10px;
    }
    .title
    {
        position: absolute;
        bottom:0px;
    }
}


/* Business card */
.business-card
{
    position: relative;
    overflow: hidden;

    .like
    {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 15px;
        background: rgba(#000,.6);
        width: auto;
        color: #fff;
        border-radius: 10px;
        padding: 2px 10px;
    }
    .title
    {
        position: absolute;
        bottom:0px;
    }
}

/* Star Rating */
.rating-block
{
    position: absolute;
    bottom: 20px;
    left:20px;
}

/* Bootstrap Element */
.progress
{
    height: 1rem;   
    .progress-bar
    {
        background-color:var(--primarycolor);
    }
}

/*===========Progress Bar ==============*/
.barfiller{
    width: 100%;
    height: 10px;
    background: var(--bodybackground); 
    box-shadow: 0 2px 2px rgba(224, 230, 237, 0.4588235294117647), 1px 6px 7px rgba(224, 230, 237, 0.4588235294117647);
    &.h-2
    {
        height: 2px;
    }
    &.h-3
    {
        height: 3px;
    }
    &.h-4
    {
        height: 4px;
    }
    &.h-5
    {
        height: 5px;
    }
    &.h-6
    {
        height: 6px;
    }
    &.h-7
    {
        height: 7px;
    }
    .fill
    {
        display: block;
        position: relative;
        width: 0px;
        height: 100%; 
    }
    .tip
    {
        margin-top: -39px;
        padding: 4px 7px;
        color: #fff;
        left: 0px;
        position: absolute;
        z-index: 2;
        background: #343a40;
        &:after
            {
            border: solid;
            border-color: #343a40 transparent;
            border-width: 6px 6px 0 6px;
            content: "";
            display: block;
            position: absolute;
            left: 13px;
            top: 100%;
            z-index: 9;
        }
        &.primary
        {
            background: var(--primarycolor);
            &:after{
                border-color: var(--primarycolor) transparent;
            }
        }
        &.secondary
        {
            background: var(--secondary);
            &:after{
                border-color: var(--secondary) transparent;
            }
        }
        &.success
        {
            background: var(--success);
            &:after{
                border-color: var(--success) transparent;
            }
        }
        &.info
        {
            background: var(--info);
            &:after{
                border-color: var(--info) transparent;
            }
        }
        &.warning
        {
            background: var(--warning);
            &:after{
                border-color: var(--warning) transparent;
            }
        }
        &.danger
        {
            background: var(--danger);
            &:after{
                border-color: var(--danger) transparent;
            }
        }
    }
}



/* Social Graph */
#social-chart
{
    height: 153px;
}
.social-dot
{
    width:10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
    &.google
    {
        background: #dd4b39;
    }
    &.facebook
    {
        background: #3b5999;
    }
    &.twitter
    {
        background: #55acee;
    }

}

/* Footer */
footer.site-footer{
    background:var(--mainbackground);
    text-align: center;
    padding: 10px 0px;
    margin-top: 20px; 
    margin-left: 250px;
    @media (max-width: 991px){
        margin-left:0px;
    }
}

/* Back to top */
.scrollup {
    width: 38px;
    height: 38px;
    position: fixed;
    bottom: 30px;
    right: 16px;
    display: none;
    z-index: 999;
    color:#fff;
    border-radius: 5px;
    line-height: 38px;
    font-size: 20px;
    font-weight: bold;
    background:var(--primarycolor);
    box-shadow: 0 1px 20px 1px var(--primarycolor);
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    &:hover{
        color:#fff;
    }
}



/* Template Settings */

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
#settings
{
    .gradient-img{
        display:none;
        .gradient-img-block
        {
            width:100px;
            height:90px;
            overflow: hidden;
            cursor: pointer;
            &.active
            {
                border:1px solid #fff;
            }
        } 
    }
    &.active  {
        .setting{
            right: 300px; 
        }
        .sidbarchat {
            margin-right: 0px;
            border-left: 1px solid var(--bordercolor);  
        }
    }
    .setting {
        background:var(--primarycolor);
        width: 35px;
        height: 35px;
        position: fixed;
        top: 45%;
        right: 0px;
        transition: all 0.3s;
        z-index: 999;
        color:#fff;
        line-height:35px;
        text-align:center;
        font-size:25px;  
        -webkit-border-top-left-radius: 50px !important;
        -webkit-border-bottom-left-radius: 50px !important;
        -moz-border-radius-topleft: 50px !important;
        -moz-border-radius-bottomleft: 50px !important;
        border-top-left-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
        h5{
            -webkit-animation: spin 4s linear infinite;
            -moz-animation: spin 4s linear infinite;
            animation: spin 4s linear infinite;
            i{
                line-height: 35px;
            }
        }
    }
    .sidbarchat {
        width: 300px;
        position: fixed;
        height: 100vh;
        top: 0px;
        right: 0px;
        background:var(--mainbackground);
        z-index: 10000;
        margin-right: -300px;
        transition: all 0.3s;
        box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.11);
        overflow-y:scroll;
    }
    .color-box
    {
        width:40px;
        height: 40px;
        border-radius: 40px;
        &.color1
        {
            background: #003366;
        }
        &.color2
        {
            background: #0bb2d4;
        }
        &.color3
        {
            background: #17b3a3;
        }
        &.color4
        {
            background: #eb6709;
        }
        &.color5
        {
            background: #76c335;
        }
        &.color6
        {
            background: #3e8ef7;
        }
    }
    input.color, input.sidebarcolor
    {
        width:40px;
        height: 40px;
    }
}



/* Dropdown Menu */
.dropdown-menu
{
    font-size: 13px;   
    background:var(--mainbackground);
    margin-top:10px;   
    border: 1px solid var(--bordercolor);
    .media
    {
        img
        {
            max-width: 40px;
        }
    }
    .dropdown-divider{
        border-top: 1px solid var(--bordercolor);
        margin: 0px;
    }

    .dropdown-item
    {
        padding:8px 10px;
        color:var(--bodycolor);
        &:hover, &:focus{
            background: transparent;
            color:var(--primarycolor);
        }

    }
}

/* App Calendar */
.fc-day-grid-event {   
    padding: 5px 5px;
}
.fc-event, .fc-event-dot, a:not([href]).fc-event {
    background-color: rgba(0, 51, 102, 0.2);
    border-color:var(--primarycolor);
    color:var(--primarycolor);
}
.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead{
    border-color:var(--bordercolor);  
}
.fc-unthemed .fc-popover, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-popover .fc-header{
    background-color: var(--bodybackground);
}
.fc-ltr .fc-h-event.fc-not-start, .fc-rtl .fc-h-event.fc-not-end
{
    position: relative;
    display: block;
    font-size: .85em;
    line-height: 1.4;
    border-radius: 3px;
    border: 1px solid #3788d8;
    margin-left: 2px;
    padding-left: 4px;
}
.pill{
    border-radius: 15px;
    &.cl-personal
    {
        background: #a7f4ec;  
    }
    &.cl-professional
    {
        background: #ddd6ff;  
    }
    &.cl-work
    {
        background: #afe0ff;  
    }
    &.cl-home
    {
        background: #ffd0f2;  
    }
    &.cl-office
    {
        background: #fdc7af;  
    }

}
/* Page Loader */
.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: var(--mainbackground);

}
.loader {
    position: absolute;       
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--primarycolor);
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



/* Model Pop Up */
.modal-content
{
    background: var(--mainbackground);
    border-color: var(--bordercolor);
    .modal-header, .modal-footer{
        border-color: var(--bordercolor);
    }
    .close{
        color: var(--bodycolor);
    }
}

/* Pop Over / Tool Tip */
.popover 
{
    background-color: var(--mainbackground);
    border-color: var(--bordercolor);

    .popover-header
    {
        background-color: var(--bodybackground); 
        border-color: var(--bordercolor);
        color: var(--primarycolor); 
    }
    .popover-body
    {
        color: var(--bodycolor);
    }
    &.bs-popover-auto[x-placement^=right]>.arrow::after, &.bs-popover-right>.arrow::after
    {
        border-right-color: var(--mainbackground); 
    }
    &.bs-popover-auto[x-placement^=right]>.arrow::before, &.bs-popover-right>.arrow::before
    {
        border-right-color: var(--bordercolor); 
    }

    &.bs-popover-auto[x-placement^=left]>.arrow::after, &.bs-popover-left>.arrow::after
    {
        border-left-color: var(--mainbackground); 
    }
    &.bs-popover-auto[x-placement^=right]>.arrow::before, &.bs-popover-right>.arrow::before
    {
        border-left-color: var(--bordercolor); 
    }

    &.bs-popover-auto[x-placement^=top]>.arrow::after, &.bs-popover-top>.arrow::after
    {
        border-top-color: var(--mainbackground); 
    }
    &.bs-popover-auto[x-placement^=top]>.arrow::before, &.bs-popover-top>.arrow::before
    {
        border-top-color: var(--bordercolor); 
    }

    &.bs-popover-auto[x-placement^=bottom]>.arrow::after, &.bs-popover-bottom>.arrow::after
    {
        border-bottom-color: var(--mainbackground); 
    }
    &.bs-popover-auto[x-placement^=bottom]>.arrow::before, &.bs-popover-bottom>.arrow::before
    {
        border-bottom-color: var(--bordercolor); 
    }


}

/* Scrollyfy */
.navbar-light .navbar-brand, .list-group-item-action
{
    color: var(--bodycolor); 
}
.list-group-item
{
    background-color: transparent;
    border-color: var(--bordercolor); 
}
.list-group-item-action:focus, .list-group-item-action:hover{
    background-color: var(--primarycolor);
    color:#fff;
}

/* Select 2 */
.select2-container--bootstrap4 .select2-selection, .select2-dropdown,
.select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-selection, .select2-container--bootstrap4.select2-container--disabled .select2-selection
{
    background-color: var(--mainbackground);
    border-color: var(--bordercolor); 
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder, .select2-container--bootstrap4 .select2-dropdown,
.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above,
.select2-search--dropdown .select2-search__field
{
    color: var(--bodycolor); 
    border-color: var(--bordercolor); 
}
.select2-search--dropdown .select2-search__field,
.select2-container .select2-search--inline .select2-search__field
{
    background-color: transparent;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b{
    border-color:var(--bodycolor) transparent transparent;
}
.select2-container .select2-search--inline .select2-search__field
{
    color: var(--bodycolor); 
    margin-top: 8px;
}

/* Sweet Alert */
.sweet-alert
{
    background-color: var(--mainbackground);
}
/* Chat App */
.chat-menu
{
    li {
        position:relative;
        border-bottom: 1px solid var(--bordercolor);
        z-index:1;
        &:after{    
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--primarycolor);
            opacity: 0;
            left:0px;
            z-index: -1;
            top:0px;
        } 
        &.active:after, &:hover:after{    
            opacity: 0.1;
        } 
        .online-status
        {
            &:before
                {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;                           
                border-radius: 8px;
                z-index: 1;
            }
            &.green:before{
                background-color: #52c41a;
            }
            &.yellow:before{
                background-color: #fadb14;
            }
        }
        &:last-child{
            border-bottom: none;
        }
        a, a:hover
            {
            color:var(--bodycolor);
            z-index: 1;
            position: inherit;
        }
        .new-message{
            width: 19px;
            height: 19px;
            line-height: 19px;
            border-radius: 10px;
            text-align: center;
        }
    }
    .call-button{
        z-index:1;
        a{
            width:30px;
            height: 30px;
            border-radius: 30px;
            color:#fff;
            display: inline-block;
            line-height: 34px;
            text-align: center;
            font-size:20px;
            &:hover{
                color:#fff;
            }
            &.call{
                background: #0abb87;
            }
            &.video-call{
                background: #ffb822;
            }
        }
    }
}
.scrollerchat
{   
    background-image: url(/images/chat.png);
    background-size: cover;
    background-position: center; 
}
.chat-box
{
    .form-control
    {
        height: 50px;
    }
    a{
        position: absolute;
        right: 22px;
        top: 22px
    }
}
.chat-contact, .chat-profile
{
    position: fixed;
    top: 94px;
    right: 0px;
    z-index: 10;
}
.chat-profile
{
    top: 134px; 
}
.chat-contact-list
{
    -webkit-border-radius: 5px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius: 5px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: all 0.5s;
    @media (max-width: 991px){
        -webkit-border-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        position: fixed;
        top: 65px;
        bottom: 0;
        -webkit-transform: translateX(-767px);
        transform: translateX(-767px);
        &.active {
            -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
            margin-left: 0px;
            z-index: 5;
        }
    }
}
.chat-user-profile
{
    -webkit-border-radius: 0px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius: 0px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 0.5s;
    @media (max-width: 991px){
        -webkit-border-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        position: fixed;
        top: 65px;
        bottom: 0;
        -webkit-transform: translateX(-767px);
        transform: translateX(-767px);
        &.active {
            -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
            margin-left: 0px;
            z-index: 5;
        }
    }
}
.speech-bubble {
    position: relative;
    background: var(--primarycolor);
    border-radius: .4em;
    color:#fff;
    z-index:1;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 100%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top-color: var(--primarycolor);
        border-bottom: 0;
        border-left: 0;
        margin-left: 0px;
        top: 20px;
        z-index:1;
    }
    &.alt
    {
        color: var(--bodycolor);
        background: transparent;
        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--primarycolor);
            opacity: 0.1;
            top:0px;
            left:0px;
            border-radius: .4em;
            z-index:1;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: auto;
            right:100%;
            width: 0;
            height: 0;
            opacity: 0.1;
            border: 20px solid transparent;
            border-right-color: var(--primarycolor);
            border-bottom: 0;
            border-left: 0;
            margin-left: 0px; 
            top: 10px;
        }
    }
    img{
        max-width: 100%;
    }
    iframe{
        max-width: 300px;
        z-index:2;
    }
}
/**************** Task Board ************/
.ui-sortable-placeholder
{
    visibility: visible !important;
    border:1px dotted var(--primarycolor);
}
/********* Flip Menu ***********/

@media (max-width: 991px)
{
    .flip-menu
    {
        -webkit-border-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        position: fixed;
        top: 49px;
        bottom: 0;
        -webkit-transform: translateX(-780px);
        transform: translateX(-780px);
        transition: all 0.5s;
        .mail-menu-section
        {
            z-index: 100;
        }
        &.active
        {

            -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
            margin-left: 0px;
            z-index: 5;
            &:before{

                position: fixed;
                background: rgba(0,0,0,0.6);
                width:100%;
                height:100vh;
            }
            .mail-menu-section
            {
                border-radius:0px;
            }

        }
        .flip-menu-close
        {
            position: absolute;
            right: -7px;
            left: auto;
            top: 0px;
            font-size: 20px;
            z-index: 100000;
        }

    }
}
.flip-menu-toggle
{
    line-height: 31px;
    font-size: 20px;
    border-right: 1px solid var(--bordercolor);
    padding: 0px 10px;
    margin-top: 3px;
}

/* ToDo App */
.todo-menu-section {
    -webkit-border-radius: 5px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius: 5px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: all 0.5s;
}
.todo-list-section {

    overflow: hidden;
    @media (min-width: 992px)
        {
        -webkit-border-radius: 0px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 0px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: all 0.5s;
        border-left:none !important;
    }
}
.todo-menu
{
    margin-top:30px;
    li{
        padding:5px 15px;
        a
        {
            font-weight: 700;
            position: relative;
            &.active, &:hover{
                color:var(--primarycolor);
                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: var(--primarycolor);
                    opacity: 0.2;
                    left: 0px;
                    top: 0px;
                    z-index: 0;
                    border-radius: 20px;
                }
            }
        }
    }
}
.todo-list
{
    padding: 0px;
    li.todo-item
    {
        border-bottom: 1px solid var(--bordercolor);
        padding:20px;
        display:flex;
        &:last-child
            {
            border-bottom: none;
        }
        h3{
            font-size: 16px;
            font-weight: 600;
        }
        p
        {
            -webkit-transition: all 0.35s ease;
            transition: all 0.35s ease;
            overflow: hidden!important;
            text-overflow: ellipsis!important;
            white-space: nowrap!important;
            max-width: calc(100vw - 730px);
            margin-bottom: 0px;
            @media (max-width: 767px) {
                max-width: calc(100vw - 200px);
            }
        }
        .max-w-auto{
            min-width: 20px;
        }
        &.trashed
        {
            .todo-content
            {
                text-decoration: line-through;
            }            
        }
        &.starred 
        {
            .icon-star
            {
                color:var(--warning);
            }
        }
        &.important 
        {
            .icon-exclamation
            {
                color:var(--warning);
            }
        }
    }
}

/* Form Control */
.form-control, .form-control:focus, .form-control:disabled, .form-control[readonly]
{
    background: transparent;
    border-color: var(--bordercolor);
    font-size: 12px;
}
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*********** Sparkline Chart **********/
#jqstooltip
{
    background: var(--mainbackground);
    color: var(--bodycolor);
    border-color: var(--bordercolor);
    width:auto !important;
    height:auto !important;
    .jqsfield
    {
        color: var(--bodycolor);  
    }
}
/* Chartist Js */
.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut
{
    stroke: var(--primarycolor);
}
.ct-grid{
    stroke: var(--bordercolor);
}
.ct-label
{
    color:var(--bodycolor);  
}

/* Height */
.height-300
{
    min-height: 300px;
}

.height-175
{
    height:175px;
    min-height: 175px;
    overflow: auto;
}
.height-200
{
    height:200px;
    min-height: 200px;
    overflow: auto;
}
.height-235
{
    height:235px;
    min-height: 235px;
    overflow: auto;
}
.height-350
{
    min-height: 350px;
}

.height-400
{
    height:400px;
    min-height: 400px;
    overflow: auto;
}
.height-500
{
    min-height: 500px;
}


/* Flot Chart */
.flot-svg{
    text{
        fill:var(--bodycolor);  
    }
}

/* Google Chart */
.google-chart{
    svg{
        rect{
            fill:var(--mainbackground); 
        }
        g{
            text, react{ fill:var(--bodycolor);  }

        }
    }
}


/* Apex Chart */

.apexcharts-canvas.dark
{
    background: transparent !important;
}

/* c3 Chart */
path.domain { stroke: var(--bordercolor); }
.tick {
    text { stroke: var(--bodycolor); }
    line{
        stroke: var(--bordercolor);
    }
}
.c3-legend-item text { stroke: var(--bodycolor); }
.c3-tooltip{
    background-color: var(--mainbackground);
    tr{
        border-color: var(--bordercolor);
        th, td{
            background-color: var(--mainbackground);
        }
    }
}


/* Mail App */
.mail-menu
{
    margin-top:30px;
    li{
        padding:5px 15px;
        a
        {
            font-weight: 700;
            position: relative;
            display: flex;
            -webkit-box-align: center!important;
            -ms-flex-align: center!important;
            align-items: center!important;
            i{
                font-size: 14px;
            }
            &.active, &:hover{
                color:var(--primarycolor);
                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: var(--primarycolor);
                    opacity: 0.2;
                    left: 0px;
                    top: 0px;
                    z-index: 0;
                    border-radius: 20px;
                }
            }
        }
    }
}
.mail-menu-section {
    -webkit-border-radius: 5px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius: 5px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: all 0.5s;
}
.mail-list-section {

    overflow: hidden;
    @media (min-width: 992px)
        {
        -webkit-border-radius: 0px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 0px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: all 0.5s;
        border-left:none !important;
    }
}
.dot
{
    width: 10px;
    height: 10px;
    border-radius: 10px;  
    margin-top: 5px;
    display: inline-block;
}
.view-email
{
    position: absolute;
    z-index: 100000000;
    background: var(--mainbackground);
    width: 100%;
    height: 100%;
    display: none;
}
.mail-app{
    li{
        border-bottom:1px solid var(--bordercolor);
        transition: transform 300ms ease;
        line-height: 20px;
        font-size:13px;
        &:hover{

            transform: scale(1.01);
            -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
            -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
            box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);

        }
        .mail-user{
            min-width: 200px;
        }
        .mail-subject{
            margin-bottom: 0px;
            line-height: 19px;
            margin-left: 15px;
            cursor: pointer;
            @media (max-width: 767px) {
                margin-left:0px;
                margin-top:10px;
            }
        }
        .mail-type-icon
        {
            font-size: 15px;
            margin-top: 2px;
        }
        .dot
        {
            width: 10px;
            height: 10px;
            border-radius: 10px;  
            margin-top: 5px;
        }
        &.unread{ font-weight: 600;
                  .mail-subject, .mail-date, i{
                      font-weight: 600;
                  }
        }
        &.personal-mail
        {
            .dot{
                background: var(--success);
            }
        }
        &.work-mail
        {
            .dot{
                background: var(--info);
            }
        }
        &.social-mail
        {
            .dot{
                background: var(--warning);
            }
        }
        &.private-mail
        {
            .dot{
                background: var(--danger);
            }
        }
        &.business-mail
        {
            .dot{
                background: var(--primarycolor);
            }
        }
        &.starred
        {
            .icon-star
            {
                color: var(--warning);  
            }            
        }
        &.important
        {
            .icon-exclamation
            {
                color: var(--warning);  
            }            
        }
        &.mail-item{
            display: none;
            &.inbox
            {
                display: inherit;
            }
        }
    }

}
.mail-toolbar
{
    a{
        margin-right:15px;
        i{
            font-size: 17px;
        }
        &:last-child{
            margin-right: 0px;
        }
        &.dropdown-item{
            i{
                font-size: 12px;
            }
        }
    }
}

/* Notes App */
.notes-menu
{
    margin-top:30px;
    li{
        padding:5px 15px;
        a
        {
            font-weight: 700;
            position: relative;
            display: flex;
            -webkit-box-align: center!important;
            -ms-flex-align: center!important;
            align-items: center!important;
            i{
                font-size: 14px;
            }
            &.active, &:hover{
                color:var(--primarycolor);
                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: var(--primarycolor);
                    opacity: 0.2;
                    left: 0px;
                    top: 0px;
                    z-index: 0;
                    border-radius: 20px;
                }
            }
        }
    }
}
.notes-menu-section {
    -webkit-border-radius: 5px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius: 5px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: all 0.5s;
}
.notes-list-section {
    padding: 15px;
    overflow: hidden;
    @media (min-width: 992px)
        {
        -webkit-border-radius: 0px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 0px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: all 0.5s;
        border-left:none !important;
    }
    .dot
    {
        width:19px;
        height: 19px;
        border-radius: 19px;
        margin-top: 0px;
    }
    .personal-note
    {
        .card{
            position:relative;
            &:before{
                content: "";
                background: var(--success);
                opacity:0.1;
                position: absolute;
                width:100%;
                height: 100%;
            }
        }
        .dot{
            background: var(--success);
        }
    }
    .work-note
    {
        .card{
            position:relative;
            &:before{
                content: "";
                background: var(--info);
                opacity:0.1;
                position: absolute;
                width:100%;
                height: 100%;
            }
        }
        .dot{
            background: var(--info);
        }
    }
    .social-note
    {
        .card{
            position:relative;
            &:before{
                content: "";
                background: var(--warning);
                opacity:0.1;
                position: absolute;
                width:100%;
                height: 100%;
            }
        }
        .dot{
            background: var(--warning);
        }
    }
    .private-note
    {
        .card{
            position:relative;
            &:before{
                content: "";
                background: var(--danger);
                opacity:0.1;
                position: absolute;
                width:100%;
                height: 100%;
            }
        }
        .dot{
            background: var(--danger);
        }
    }
    .business-note
    {
        .card{
            position:relative;
            &:before{
                content: "";
                background: var(--primarycolor);
                opacity:0.1;
                position: absolute;
                width:100%;
                height: 100%;
            }  
        }

        .dot{
            background: var(--primarycolor);
        }
    }
    .starred
    {
        .icon-star
        {
            color: var(--warning);  
        }            
    }
    .important
    {
        .icon-exclamation
        {
            color: var(--warning);  
        }            
    }
    .notes-tool
    {
        font-size: 18px;
        line-height: 18px;
    }
}





/* Quill Editor */
.ql-snow .ql-stroke
{
    stroke: var(--bodycolor);
}
.ql-toolbar.ql-snow, .ql-container.ql-snow, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options
{
    border-color: var(--bordercolor);
}
.ql-editor
{
    position: absolute;
    width: 100%;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-snow .ql-picker.ql-header .ql-picker-item::before,
.ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before,
.ql-editor.ql-blank::before
{
    color: var(--bodycolor); 
}
.ql-snow .ql-picker-options
{
    background-color: var(--mainbackground); 
}


/* Upload Button Wrapper */
.file {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 2.5rem;
}
.file input {
    min-width: 14rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
}
.file-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color:var(--bodycolor);
    background-color: var(--mainbackground);
    border: .075rem solid var(--bordercolor);
    border-radius: .25rem;
    box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.file-custom:after {
    content: "Choose file...";
}
.file-custom:before {
    position: absolute;
    top: -.075rem;
    right: -.075rem;
    bottom: -.075rem;
    z-index: 6;
    display: block;
    content: "Browse";
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: var(--bodycolor);
    background-color: var(--mainbackground);
    border: .075rem solid var(--bordercolor);
    border-radius: 0 .25rem .25rem 0;
}

/* Focus */
.file input:focus ~ .file-custom {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}



/* Form Input */
.input-group-prepend
{
    .input-group-text
    {
        background-color: transparent;
        border: 1px solid var(--bordercolor);
        border-right: none;
        font-size: 13px;
    }

}
.input-group-append
{
    .input-group-text
    {
        background-color: transparent;
        border: 1px solid var(--bordercolor);
        border-left: none;
        font-size: 13px;
    }

}

/* Bootstrap 4 checkboxes */
.custom-control{
    line-height: 2;
    .custom-control-label
    {
        &::before { 
            width: 18px;
            height: 18px; 
            background: transparent;
        }  
    }

    .custom-control-input:checked ~ .custom-control-label
        {
        &::before { 
            border-color: var(--primarycolor);
            background-color: var(--primarycolor);  

        }
        &::after{    
            background-image: none;
            content: "";    
            width: 7px;
            height: 12px;
            border: solid #fff;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 5px;
            left: -18px;
        }
        &.checkbox-primary{
            &::before { 
                border-color: var(--primarycolor);
                background-color: var(--primarycolor);
            }
            &.outline::after{
                border-color: var(--primarycolor);
            }
        }
        &.checkbox-secondary{
            &::before { 
                border-color: var(--secondary);
                background-color: var(--secondary);
            }
            &.outline::after{
                border-color: var(--secondary);
            }
        }
        &.checkbox-warning{
            &::before { 
                border-color: var(--warning);
                background-color: var(--warning);
            } 
            &.outline::after{
                border-color: var(--warning);
            }
        }
        &.checkbox-danger{
            &::before { 
                border-color: var(--danger);
                background-color: var(--danger);
            } 
            &.outline::after{
                border-color: var(--danger);
            }
        }
        &.checkbox-info{
            &::before { 
                border-color: var(--info);
                background-color: var(--info);
            } 
            &.outline::after{
                border-color: var(--info);
            }
        }
        &.checkbox-dark{
            &::before { 
                border-color: var(--dark);
                background-color: var(--dark);
            } 
            &.outline::after{
                border-color: var(--dark);
            }
        }
        &.checkbox-success{
            &::before { 
                border-color: var(--success);
                background-color: var(--success);
            }  
            &.outline::after{
                border-color: var(--success);
            }
        }
        &.outline::before{
            background-color: transparent;
        }

    }
    &.custom-checkbox {
        .custom-control-label::before
        {
            border-radius: 0px;
            background-color: transparent;
            border-color: var(--bordercolor);
        }
        .custom-control-input:disabled~.custom-control-label::before{
            background-color: transparent;
            border-color: var(--bordercolor);
        }
        .custom-control-input:disabled:checked~.custom-control-label::before{
            background-color: var(--primarycolor);
            opacity: 0.7;
        }
    }
    &.custom-radio{
        .custom-control-input:disabled:checked~.custom-control-label::before{
            background-color: var(--primarycolor);
            opacity: 0.7;
        }
        .custom-control-input:checked ~ .custom-control-label
            {            
            &::after{    
                background-image: none;
                content: "";
                width: 6px;
                height: 6px;
                border: none;
                border-radius: 7px;
                top: 10px;
                left: -18px;
                background: #fff;
            }

            &.checkbox-primary{

                &.outline::after{
                    background: var(--primarycolor);
                }
            }
            &.checkbox-secondary{               
                &.outline::after{
                    background: var(--secondary);
                }
            }
            &.checkbox-warning{               
                &.outline::after{
                    background: var(--warning);
                }
            }
            &.checkbox-danger{               
                &.outline::after{
                    background: var(--danger);
                }
            }
            &.checkbox-info{                
                &.outline::after{
                    background: var(--info);
                }
            }
            &.checkbox-dark{               
                &.outline::after{
                    background: var(--dark);
                }
            }
            &.checkbox-success{                
                &.outline::after{
                    background: var(--success);
                }
            }
        }
    }
}

/* toggle Switch */
.toggle-handle
{
    background: var(--mainbackground);
    border-color: var(--bordercolor);
}
.toggle.btn
{
    margin-bottom: 10px;
}

/* Float Input */
.input-group-text
{
    color: var(--bodycolor);
}
.form-group {
    position: relative;
    .form-control
    {
        + .form-control-placeholder {
            position: absolute;
            top: 0;
            padding: 7px 0 0 13px;
            transition: all 200ms;
            opacity: 0.5;
        }
        &:focus + .form-control-placeholder {  
            transform: translate3d(0, -100%, 0);
            opacity: 1;
        }
        &.form-control-lg
        {
            + .form-control-placeholder {              
                padding: 14px 0 0 13px;                
            }
            &.float-input:focus {
                padding: 1.2rem 1rem .3rem 1rem;
                + .form-control-placeholder {  
                    font-size: 70%;
                    transform: translate3d(0, -40%, 0);                
                }
            }
        }

    }
    .input-group
    {
        .input-group-prepend + .form-control + .form-control-placeholder {
            left:40px;
        }  
    }   

}

.input-primary
{
    .form-control
    {
        &:focus {
            border-color:var(--primarycolor);
            + .form-control-placeholder {  
                color:var(--primarycolor);                
            } 
        }  
    }
}
.input-secondary
{
    .form-control
    {
        &:focus {
            border-color:var(--secondary);
            + .form-control-placeholder {  
                color:var(--secondary);                
            } 
        }  
    }
}
.input-warning
{
    .form-control
    {
        &:focus {
            border-color:var(--warning);
            + .form-control-placeholder {  
                color:var(--warning);                
            } 
        }  
    }
}
.input-danger
{
    .form-control
    {
        &:focus {
            border-color:var(--danger);
            + .form-control-placeholder {  
                color:var(--danger);                
            } 
        }  
    }
}

.input-info
{
    .form-control
    {
        &:focus {
            border-color:var(--info);
            + .form-control-placeholder {  
                color:var(--info);                
            } 
        }  
    }
}
.input-dark
{
    .form-control
    {
        &:focus {
            border-color:var(--dark);
            + .form-control-placeholder {  
                color:var(--dark);                
            } 
        }  
    }
}
.input-success
{
    .form-control
    {
        &:focus {
            border-color:var(--success);
            + .form-control-placeholder {  
                color:var(--success);                
            } 
        }  
    }
}


/*  Form Wizard Page Css */
.wizard{
    position:relative;
    z-index:1;
    .round-tab{
        i{
            width: 50px;
            height: 50px;
            background-color: var(--primarycolor);
            border: 2px solid var(--primarycolor);
            font-size: 1.25rem;
            line-height: 45px;
            text-align: center !important;
            top: 15px;
            z-index: 99; 
            display: inline-block;
        }
    }
    .nav-link{
        &:hover{ color: var(--primarycolor) !important;}
    }
    .nav-link.active{
        color: var(--primarycolor);
        i{
            background-color: var(--mainbackground);
            border-color: var(--primarycolor);
            color: var(--primarycolor) !important;
        }
    }
    .connecting-line{
        height: 2px;
        background: var(--primarycolor);
        position: absolute;
        width: 99%;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 40px;
        z-index: 1;

    }
    &.dark{

        .round-tab{
            i{
                color: var(--primarycolor);
                background-color: #fff;
                border: 2px solid var(--bordercolor);;
            }
        }
        .connecting-line{ background-color: #fff;}
        .form-control{ color: #fff !important; border-color:#fff;}
        .nav-link{
            color:#fff;
            .small{
                color:#fff;
            }
            &:hover{ color: #fff !important;}
        }
        .nav-link.active{
            i{
                background-color: var(--primarycolor);
                border-color: #fff;
                color: #fff !important;
            }
        }
    }
}
.wizard-dark
{
    .nav-tabs{
        .nav-link{
            &.active{
                border-bottom: 1px solid #fff;
            }
        }
    }
    .form-control{ color: #fff !important; border-color:#fff;}
}

/* File Upload */
.file-upload input[type='file'] {
    display: none;
}
.custom-file-label
{
    background-color: transparent;
    border-color:  var(--bordercolor);
    color:var(--bodycolor);
    &:after
        {
        background-color: var(--bodybackground);
        color:var(--bodycolor);
    }
}


/* Dropzone Css */
#dropzone [type="file"], .dropzone [type="file"] {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80px; }

.dropzone [type="file"] {
    width: 20px !important; }

.redial-process {
    width: 271px;
    height: 271px;
    background: -webkit-gradient(linear, left bottom, right top, color-stop(15%, #3a5fd8), color-stop(100%, white)); }
.redial-process .redial-middle-bar {
    width: 195px;
    height: 195px;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 40px; }

.dropzone {
    min-height: 150px;
    border: 2px dashed;
    padding: 20px 20px;
    font-size: 20px;
    cursor: pointer;
    text-align: center; 
    &.h-auto
    {
        min-height: auto;
    }
}

.dropzone .dz-message {
    margin: 2em 0; }

.dropzone-primary { 
    color: var(--primarycolor);
    border-color: var(--primarycolor); }

.dropzone-danger { 
    color: var(--danger);
    border-color: var(--danger);
}
.dropzone-secondary { 
    color: var(--secondary);
    border-color: var(--secondary);
}
.dropzone-dark { 
    color: var(--dark);
    border-color: var(--dark);
}
.dropzone-warning { 
    color: var(--warning);
    border-color: var(--warning);
}
.dropzone-success {
    color: var(--success);
    border-color: var(--success);
}
.dropzone-info {
    color: var(--info);
    border-color: var(--info);
}


/* Icheck */
.skin
{
    .list {
        li{
            margin-bottom: 15px;
        }
    }
}

/* Image Cropper */
.carbonads {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    overflow: hidden;
    padding: 1rem;
}

.carbon-wrap {
    overflow: hidden;
}

.carbon-img {
    clear: left;
    display: block;
    float: left;
}

.carbon-text,
.carbon-poweredby {
    display: block;
    margin-left: 140px;
}

.carbon-text,
.carbon-text:hover,
.carbon-text:focus {
    color: #fff;
    text-decoration: none;
}

.carbon-poweredby,
.carbon-poweredby:hover,
.carbon-poweredby:focus {
    color: #ddd;
    text-decoration: none;
}

@media (min-width: 768px) {
    .carbonads {
        float: right;
        margin-bottom: -1rem;
        margin-top: -1rem;
        max-width: 360px;
    }
}

.footer {
    font-size: 0.875rem;
}

.heart {
    color: #ddd;
    display: block;
    height: 2rem;
    line-height: 2rem;
    margin-bottom: 0;
    margin-top: 1rem;
    position: relative;
    text-align: center;
    width: 100%;
}

.heart:hover {
    color: #ff4136;
}

.heart::before {
    border-top: 1px solid #eee;
    content: " ";
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
}

.heart::after {
    background-color: #fff;
    content: "?";
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: relative;
    z-index: 1;
}

.img-container,
.img-preview {
    background-color: #f7f7f7;
    text-align: center;
    width: 100%;
}

.img-container {
    margin-bottom: 1rem;
    max-height: 497px;
    min-height: 200px;
}

@media (min-width: 768px) {
    .img-container {
        min-height: 497px;
    }
}

.img-container > img {
    max-width: 100%;
}

.docs-preview {
    margin-right: -1rem;
}

.img-preview {
    float: left;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    overflow: hidden;
}

.img-preview > img {
    max-width: 100%;
}

.preview-lg {
    height: 9rem;
    width: 16rem;
}

.preview-md {
    height: 4.5rem;
    width: 8rem;
}

.preview-sm {
    height: 2.25rem;
    width: 4rem;
}

.preview-xs {
    height: 1.125rem;
    margin-right: 0;
    width: 2rem;
}

.docs-data > .input-group {
    margin-bottom: 0.5rem;
}

.docs-data .input-group-prepend .input-group-text {
    min-width: 4rem;
    color:var(--bodycolor);
}

.docs-data .input-group-append .input-group-text {
    min-width: 3rem;
    color:var(--bodycolor);
}

.docs-buttons > .btn,
.docs-buttons > .btn-group,
.docs-buttons > .form-control {
    margin-bottom: 0.5rem;
    margin-right: 0.25rem;
}

.docs-toggles > .btn,
.docs-toggles > .btn-group,
.docs-toggles > .dropdown {
    margin-bottom: 0.5rem;
}

.docs-tooltip {
    display: block;
    margin: -0.5rem -0.75rem;
    padding: 0.5rem 0.75rem;
}

.docs-tooltip > .icon {
    margin: 0 -0.25rem;
    vertical-align: top;
}

.tooltip-inner {
    white-space: normal;
}

.btn-upload .tooltip-inner,
.btn-toggle .tooltip-inner {
    white-space: nowrap;
}

.btn-toggle {
    padding: 0.5rem;
}

.btn-toggle > .docs-tooltip {
    margin: -0.5rem;
    padding: 0.5rem;
}

@media (max-width: 400px) {
    .btn-group-crop {
        margin-right: -1rem !important;
    }

    .btn-group-crop > .btn {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .btn-group-crop .docs-tooltip {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.docs-options .dropdown-menu {
    width: 100%;
}

.docs-options .dropdown-menu > li {
    font-size: 0.875rem;
    padding: 0.125rem 1rem;
}

.docs-options .dropdown-menu .form-check-label {
    display: block;
}

.docs-cropped .modal-body {
    text-align: center;
}

.docs-cropped .modal-body > img,
.docs-cropped .modal-body > canvas {
    max-width: 100%;
}


/* Typeahead */


.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999
}

.tt-menu {
    width: 422px;
    margin: 12px 0;
    padding: 8px 0;
    background-color: var(--mainbackground);
    border: 1px solid var(--bordercolor);
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
}

.tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: var(--primarycolor);
}

.tt-suggestion.tt-cursor {
    color: #fff;
    background-color: var(--primarycolor);

}

.tt-suggestion p {
    margin: 0;
}

.twitter-typeahead
{
    width:100%;
}

/* X-Editable */
.editable-checklist label {
    display: block;
}

/* Summernote */
.note-editor
{   
    &.note-frame .note-editable {
        border: none;
    }
    .note-editable{
        border: 1px solid var(--bordercolor);
        padding:15px;  
    }

    &.note-frame{
        z-index: 0;
        border-color: var(--bordercolor);   
    }

}

/* tablesaw */
.tablesaw
{
    &.table-bordered
    {
        td, th{
            border-color: var(--bordercolor);  
        }      
    }  
    .tablesaw-swipe-cellpersist
    {
        border-color: var(--bordercolor); 
    }
}
.tablesaw-columntoggle-btnwrap.visible .tablesaw-columntoggle-popup, .tablesaw-btn
{
    background-color:var(--mainbackground);
    border-color: var(--bordercolor); 
    box-shadow: none;
}
.tablesaw-bar .tablesaw-bar-section .tablesaw-btn
{
    color:var(--bodycolor);
    &:after{
        padding-top: 1.5em;
    }
}
.tablesaw-stack tbody tr{
    border-color: var(--bordercolor);  
}

/* Table / Layout */
.table:not(.table-dark){
    color:var(--bodycolor); 
    thead th, tfoot th, td, th
    {
        padding: 1em;
        border-color: var(--bordercolor); 
    }
    &.table-bordered
    {
        border-color: var(--bordercolor);  
    }
}
.table.pick-table
{
    thead th, tfoot th, td, th
    {
        padding-top:8px;
        padding-bottom: 8px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
.layout-primary
{  
    thead{
        background-color:var(--primarycolor);
        color:#fff;
        th{
            border-bottom: none;
        }
    }
    &.bordered{
        border:2px solid var(--primarycolor);
    }

}
.layout-secondary
{  
    thead{
        background-color:var(--secondary);
        color:#fff;
        th{
            border-bottom: none;
        }
    }
    &.bordered{
        border:2px solid var(--secondary);
    }

}
.layout-danger
{  
    thead{
        background-color:var(--danger);
        color:#fff;
        th{
            border-bottom: none;
        }
    }
    &.bordered{
        border:2px solid var(--danger);
    }

}
.layout-warning
{  
    thead{
        background-color:var(--warning);
        color:#fff;
    }
    &.bordered{
        border:2px solid var(--warning);
    }

}
.layout-info
{  
    thead{
        background-color:var(--info);
        color:#fff;
        th{
            border-bottom: none;
        }
    }
    &.bordered{
        border:2px solid var(--info);
    }

}
.layout-success
{  
    thead{
        background-color:var(--success);
        color:#fff;
        th{
            border-bottom: none;
        }
    }
    &.bordered{
        border:2px solid var(--success);
    }

}
.layout-dark
{  
    thead{
        background-color:var(--dark);
        color:#fff;
        th{
            border-bottom: none;
        }
    }
    &.bordered{
        border:2px solid var(--dark);
    }

}


/* datatable */
.pagination
{
    .page-item.disabled .page-link
    {
        background-color: transparent;
        border-color: var(--bordercolor);
        color:var(--bodycolor);
    }
    .page-link
    {
        background-color: transparent;
        border-color: var(--bordercolor);
        color:var(--primarycolor);
    }
    .page-item.active .page-link
    {
        background-color: var(--primarycolor);
        border-color: var(--primarycolor);
        color:#fff;
        z-index:1;
    }

}

/* Footable */
.footable-sortable
{
    span{
        margin-left: 10px;
    }
}
.footable-pagination-wrapper{
    .pagination
    {
        justify-content: center;
        li:first-child .page-link {
            margin-left: 0;
            border-top-left-radius: .25rem;
            border-bottom-left-radius: .25rem;
        }
        li:last-child .page-link {
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }
        .footable-page-link
        {
            padding: .5rem .75rem;
            border: 1px solid var(--bordercolor);
        }
        .active
        {
            .footable-page-link
            {
                border: 1px solid var(--primarycolor); 
                background-color: var(--primarycolor); 
                color:#fff;
            }
        }

    } 
}
#example .open > .dropdown-menu {
    display: block;
}


/* Js grid */
.jsgrid
{
    height: auto !important;
    min-height: 400px;
}
.jsgrid-pager{

    text-align:  center;
    margin-top:20px;
    span{
        padding: 0px;
    }
    span:first-child a {
        margin-left: 0;
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }
    span:last-child a {
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }
    .jsgrid-pager-page a, .jsgrid-pager-nav-button
    {
        padding: .5rem .75rem;
        border: 1px solid var(--bordercolor);
    }
    .jsgrid-pager-current-page
    {
        padding: .5rem .75rem;
        border: 1px solid var(--primarycolor); 
        background-color: var(--primarycolor); 
        color:#fff;

    }


}


/* Tablesaw/Responsive Table */
.tablesaw-columntoggle td.title a,
.tablesaw-swipe td.title a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10em;
    max-width: 40vw;
}
@media (min-width: 40em) {
    td.title {
        max-width: 12em;
    }
}

/* Buttons */

.btn-secondary 
{
    background-color: var(--secondary );   
    border-color: var(--secondary );
}
.btn-success 
{
    background-color: var(--success ); 
    border-color: var(--success );
}
.btn-info
{
    background-color: var(--info);   
    border-color: var(--info );
}
.btn-warning
{
    background-color: var(--warning);   
    border-color: var(--warning );
}
.btn-dark
{
    background-color: var(--dark); 
    border-color: var(--dark );
}
.btn-danger
{
    background-color: var(--danger); 
    border-color: var(--danger );
}
.btn
{
    &.rounded-btn
    {
        border-radius: 60px;
        padding: 7px 18px;
    }  
}

/* Accordion Alt */
.accordion-alt
{
    a {

        line-height: 50px; 
        &:after {
            font-family: 'FontAwesome';
            content: "\f068";
            float: left;
            width: 50px;
            margin-right: 15px;
            text-align: center;
            background-color: var(--primarycolor);
            color: #fff;
        }
        &.collapsed:after {
            content: "\f067";
        } 
    }
}



/* Lockscreen Page */
.lockscreen
{
    max-width:600px;
    float: none;
    margin: 0 auto;
    background:var(--mainbackground);

    border-radius: 20px;
    overflow:hidden;
    -webkit-box-shadow: -2px 7px 23px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 7px 23px -5px rgba(0,0,0,0.75);
    box-shadow: -2px 7px 23px -5px rgba(0,0,0,0.75);
    .lock-image
    {
        background-image: url(/images/cap3.jpg);
        width:250px;
        float: left;
        background-size: cover;
        @media (max-width: 575px)
            {
            height:300px; 
        }

    }
    .login-form
    {
        padding: 20px;
        width:350px;

        float: left; 
    }
}

/* Timeline Css */
.timeline-app { 
    padding: 0;
    margin-bottom: 22px;
    list-style: none;
    background: 0 0;
    overflow:hidden;
    position: relative;
    .timeline-header {
        position: relative;
        z-index: 6;
        display: block;       

        clear: both;       
        text-align: center;
        text-transform: uppercase;
    }
    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 2px;
        margin-left: -1px;
        content: "";
        background-color: var(--primarycolor);
    }
    .timeline-item {
        position: relative;
        display: block;
        float: left;
        width: 50%;
        padding-right: 40px;
        margin-bottom: 60px;
        padding-top:0px;
        margin-right:50%;  
        &:last-child
            {
            margin-bottom: 0px;
        }
        .time-strip {
            position: absolute;
            left:calc(100% + 30px);
            font-weight: bold;
            color: var(--primarycolor);
            line-height: 40px;
        }
        &.reverse {
            float: right;
            padding-right: 0;
            padding-left: 40px;
            clear: right;
            margin-right: 0px;
        }
        .timeline-dot {
            position: absolute;
            top: 0px;
            right: 0;
            z-index: 11;           
            text-align: center;
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            margin-right: -20px;
            line-height: 45px;
            color: #fff;
            background-color: var(--primarycolor);
            font-size: 20px;
        }
        &.reverse {
            float: right;
            padding-right: 0;
            padding-left: 40px;
            margin-top: 0px;
            clear: right;
            .time-strip {
                left: -60px;
            }
            .timeline-dot {
                margin-left: -20px;
                right: auto;
                left: 0;
            }
        }
    }
}

.timeline-app-alt { 
    padding: 0;
    margin-bottom: 22px;
    list-style: none;
    background: 0 0;
    overflow:hidden;
    position: relative;   
    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 20px;
        width: 2px;
        margin-left: -1px;
        content: "";
        background-color: var(--primarycolor);
    }
    .timeline-item {
        position: relative;
        display: block;
        float: left;        
        margin-left: 60px;
        margin-bottom: 60px;
        padding-top:0px;    
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 16px;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-right-color: #fff;
            border-left: 0;
            border-bottom: 0;
            margin-top: -10px;
            margin-left: -18px;
        }
        &:last-child
            {
            margin-bottom: 0px;
        }       

        .timeline-dot {
            position: absolute;
            top: 0px;
            left: -60px;
            z-index: 11;           
            text-align: center;
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            margin-right: -20px;
            line-height: 45px;
            color: #fff;
            background-color: var(--primarycolor);
            font-size: 20px;
        }

    }
}
.timeline{
    &:after{
        content: "";
        position: absolute;
        left: 50%;
        top: 2px;
        width: 3px;
        height: 100%;
        border-right: 3px solid var(--bordercolor);
    }
}
.timeline-point{
    &:after{
        content: ""; 
        position: absolute;
        left: 104%;
        width: 11px;
        height: 11px;
        top: 38px;
        background: var(--primarycolor);;
        border-radius: 50px;
        z-index: 1;
    }
    &:before{
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        left: 105%;
        background: #e6e6e6;
        border-radius: 50px;
        top: 41px;
        z-index: 9;
    }
}
.timeline-point2{
    &:after{
        left: -18px;
    }
    &:before{
        left: -15px;
    }
}   
.list-inline{
    .list-inline-item{
        .counter-box{
            &:after{
                content: ""; 
                position: absolute;
                right: -29px;
                top: 0;              
                width: 3px;
                height: 60px;
            }
        }
        &:last-child .counter-box:after { display: none; }
    }
}

/* Portfolio/ Gallery */
.caption-bg:hover, .portfolio-block .caption-bg:hover {
    opacity: 1;
}
.filter
{
    a
    {
        border: 1px solid var(--bordercolor);
        border-radius: 0;
        padding: 7px 15px;
        margin-right: 5px;
        text-transform: none;
        color: var(--bodycolor);;
        font-weight: 400;
        font-size: 12px;
        display:block;
        margin-bottom:10px;
        &.active, &:hover
            {
            background: var(--primarycolor);
            color: #fff;
            border-color: var(--primarycolor);
        }
    }
}

/* Price Table */
.price-table
{
    .price-block
    {
        background: var(--primarycolor);
        color:#fff;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        line-height: 100px;
        border-radius: 100px;
        font-size: 30px;
        margin-bottom: 10px;
        &.active
        {
            background: #fff;
            color:var(--primarycolor);
        }
    }
}

/* icon font */
.font-icon-list
{
    text-align: center;
    width:19%;
    display:inline-block;
    @media (max-width: 991px)
        {
        width:30%;
    }
    @media (max-width: 767px)
        {
        width:40%;
    }
    @media (max-width: 500px)
        {
        width:80%;
    }
    i, span{
        font-size: 30px;
        display: block;
        &+ span
        {
            font-size: 14px;
        }
    }
    fieldset{
        display: none;
    }
    .font-icon-name{
        border:none;
        text-align:center;
    }
}
.iconlist
{
    padding:0px;
    margin:0px;
    li
    {
        padding: .5rem;
        margin-bottom: .5rem;
        border:1px solid var(--bordercolor);
        margin-left: .25rem;
        margin-right: .25rem;
        text-align: center;
        width:19%;
        display:inline-block;
        list-style: none;
        &:before{
            font-size: 30px;
            display: block;
        }
        @media (max-width: 991px)
            {
            width:30%;
        }
        @media (max-width: 767px)
            {
            width:40%;
        }
        @media (max-width: 500px)
            {
            width:80%;
        }
        i
        {
            font-size: 30px;
            display: block;
        }
    }
}

.flag-wrapper 
{
    width: 100%;
    display: inline-block;
    position: relative;   
    overflow: hidden;
    margin-bottom: 20px;
    .flag {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    &:after {
        padding-top: 75%;
        display: block;
        content: '';
    }
}

/* Conatct List */
.contact-menu-section {
    -webkit-border-radius: 5px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius: 5px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: all 0.5s;
}
.contact-list-section {

    overflow: hidden;
    @media (min-width: 992px)
        {
        -webkit-border-radius: 0px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 0px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: all 0.5s;
        border-left:none !important;
    }
}
.contact-menu
{
    margin-top:30px;
    li{
        padding:5px 15px;
        a
        {
            font-weight: 700;
            position: relative;
            &.active, &:hover{
                color:var(--primarycolor);
                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: var(--primarycolor);
                    opacity: 0.2;
                    left: 0px;
                    top: 0px;
                    z-index: 0;
                    border-radius: 20px;
                }
            }
        }
    }
}
.contacts
{
    padding: 0px;
    margin: 0px;
    p{
        margin-bottom: 0px;
    }
    &.list {

        @media (max-width: 767px)
            {
            overflow-x: scroll;
        }
        .contact
        {
            padding: 10px 15px;
            display: inline-flex; 
            width: 100%;
            font-size:15px;
            @media (max-width: 767px)
                {
                width:auto;
            }
            .contact-content
            {
                -ms-flex-direction: row;
                flex-direction: row;
                -ms-flex-align: center;
                align-items: center;
                padding: .75rem .625rem;
                position: relative;
                display: inline-flex;
                min-width: 0;
                word-wrap: break-word;
                justify-content: space-between;              

                border-radius: 6px;
                padding: 13px 18px;
                width: 100%;               
                min-width: 767px;
                transition: all 0.35s ease;
                box-shadow: 0px 0px 0px 1px rgba(126,142,177,0.12);
                > div:last-child
                    {
                    margin-left: 10px;
                    display: flex; 
                    a{
                        margin: 0px 10px;
                    }
                }
                .contact-phone
                {
                    white-space: nowrap;
                }
            }
            .user-image
            {
                width: 50px;
                border-radius: 5px;
                align-self: center;
            }
            .contact-profile
            {
                display: inline-flex;
                align-self: center;
                min-width: 250px;
            }
            .contact-info
            {
                padding:0px 15px;
            }
            .contact-email, .contact-location
            {
                min-width: 130px;

            }
        }
    }

    &.grid
    {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;  
        margin:15px 0px;
        .contact
        {
            margin-bottom: 30px;
            border-radius: 6px;
            width: 100%;           
            transition: all 0.35s ease;
            width: 33%;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            @media (max-width: 1199px)
                {
                -ms-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
            @media (max-width: 767px)
                {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media (max-width: 575px)
                {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
            .contact-content
            {
                padding: 13px 18px;
                -webkit-box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
                box-shadow: 0px 0px 0px 1px rgba(126,142,177,0.12);
                border-radius: 6px;
                font-size: 14px;

            }
            .user-image
            {
                border-radius: 10px;
                margin-bottom: 15px;
            }
            div{
                margin-bottom: 15px;
            }
            p{
                margin-bottom: 0px;
            }
        }
    }
}



/* File Manager */
.document-menu-section {
    -webkit-border-radius: 5px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius: 5px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: all 0.5s;
}
.document-list-section {

    overflow: hidden;
    @media (min-width: 992px)
        {
        -webkit-border-radius: 0px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 0px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: all 0.5s;
        border-left:none !important;
    }
}
.document-menu
{
    margin-top:30px;
    li{
        padding:5px 15px;
        a
        {
            font-weight: 700;
            position: relative;
            &.active, &:hover{
                color:var(--primarycolor);
                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: var(--primarycolor);
                    opacity: 0.2;
                    left: 0px;
                    top: 0px;
                    z-index: 0;
                    border-radius: 20px;
                }
            }
        }
    }
}
.documents
{
    padding: 0px;
    margin: 0px;
    p{
        margin-bottom: 0px;
    }
    &.list {

        @media (max-width: 767px)
            {
            overflow-x: scroll;
        }
        .document
        {
            padding: 10px 15px;
            display: inline-flex; 
            width: 100%;
            font-size:15px;
            @media (max-width: 767px)
                {
                width:auto;
            }
            .document-content
            {
                -ms-flex-direction: row;
                flex-direction: row;
                -ms-flex-align: center;
                align-items: center;
                padding: .75rem .625rem;
                position: relative;
                display: inline-flex;
                min-width: 0;
                word-wrap: break-word;
                justify-content: space-between;              

                border-radius: 6px;
                padding: 13px 18px;
                width: 100%;               
                min-width: 767px;
                transition: all 0.35s ease;
                box-shadow: 0px 0px 0px 1px rgba(126,142,177,0.12);
                > div:last-child
                    {
                    margin-left: 10px;
                    display: flex; 
                    a{
                        margin: 0px 10px;
                    }
                }
                .document-phone
                {
                    white-space: nowrap;
                }
            }
            .user-image
            {
                width: 50px;
                border-radius: 5px;
                align-self: center;
            }
            .document-profile
            {
                display: inline-flex;
                align-self: center;
                min-width: 250px;
                i{
                    font-size: 50px;
                }
            }
            .document-info
            {
                padding:0px 15px;
            }
            .document-email, .document-location
            {
                min-width: 130px;

            }
        }
    }

    &.grid
    {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;  
        margin:15px 0px;
        .document
        {
            margin-bottom: 30px;
            border-radius: 6px;
            width: 100%;          
            transition: all 0.35s ease;
            width: 33%;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            @media (max-width: 1199px)
                {
                -ms-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
            @media (max-width: 767px)
                {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media (max-width: 575px)
                {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
            .document-content
            {
                padding: 13px 18px;
                -webkit-box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
                box-shadow: 0px 0px 0px 1px rgba(126,142,177,0.12);
                border-radius: 6px;
                font-size: 14px;

            }
            .document-profile i
            {
                margin: 0 auto;
                float: none;
                display: table;
                font-size: 120px;
            }
            div{
                margin-bottom: 15px;
            }
            p{
                margin-bottom: 0px;
            }
        }
    }
}



/* Invoice List */
.view-invoice {
    position: absolute;
    z-index: 100000000;
    background: var(--mainbackground);
    width: 100%;
    height: 100%;
    display: none;
    overflow-y: scroll;
    overflow-x: hidden;
}
.invoice-menu-section {
    -webkit-border-radius: 5px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius: 5px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: all 0.5s;
}
.invoice-list-section {

    overflow: hidden;
    @media (min-width: 992px)
        {
        -webkit-border-radius: 0px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius: 0px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: all 0.5s;
        border-left:none !important;
    }
}
.invoice-menu
{
    margin-top:30px;
    li{
        padding:5px 15px;
        a
        {
            font-weight: 700;
            position: relative;
            &.active, &:hover{
                color:var(--primarycolor);
                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: var(--primarycolor);
                    opacity: 0.2;
                    left: 0px;
                    top: 0px;
                    z-index: 0;
                    border-radius: 20px;
                }
            }
        }
    }
}
.invoices
{
    padding: 0px;
    margin: 0px;

    @media (max-width: 767px)
        {
        overflow-x: scroll;
    }
    .invoice
    {
        padding: 10px 15px;
        display: inline-flex; 
        width: 100%;
        font-size:15px;

        @media (max-width: 767px)
            {
            width:auto;
        }
        .invoice-content
        {
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-align: center;
            align-items: center;
            padding: .75rem .625rem;
            position: relative;
            display: inline-flex;
            min-width: 0;
            word-wrap: break-word;
            justify-content: space-between; 
            border-radius: 6px;
            padding: 13px 18px;
            width: 100%;               
            min-width: 767px;
            transition: all 0.35s ease;
            box-shadow: 0px 0px 0px 1px rgba(126,142,177,0.12);
            .invoice-info{
                width: 20%;
                cursor: pointer;
                @media (max-width: 767px)
                    {
                    width:auto;
                }
            }
            .invoice-status-info{
                width: 10%;
                @media (max-width: 767px)
                    {
                    width:auto;
                }
            }
            > div:last-child
                {
                margin-left: 10px;
                display: flex; 
                width:10%;
                a{
                    margin: 0px 10px;
                }
            }    
            p{
                margin-bottom: 0px;
            }
        }
        .invoice-status{
            line-height: 1.4;
            padding: 3px 6px;
            font-size: 11px;
            font-weight: 600;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            position: relative;
            overflow:hidden;
            border-radius:5px;
            display: inline-block;
            border:1px solid var(--primarycolor);
            width:80px;
            height:25px;
            margin-top:5px;
            &:before{
                content:"Generated";
                position: absolute;
                width: 100%;
                height: 100%;
                color: var(--primarycolor);
                text-align: center;
                left: 0px;
                top: 0px;
                line-height: 22px;
            }
            &:after{
                content:"";
                position: absolute;
                width: 100%;
                height: 100%;
                background: var(--primarycolor);
                opacity: 0.2;
                left: 0px;
                top: 0px;
                z-index: 0;
            }
        }

        &.paid-invoice
        {
            .invoice-status{
                border:1px solid var(--success);
                color:var(--primarycolor);               
                &:after{                   
                    background: var(--success);                   
                }
                &:before{
                    content:"Paid";              
                    color: var(--success);

                }
            }
        }
        &.pending-invoice
        {
            .invoice-status{
                border:1px solid var(--info);
                color:var(--info);               
                &:after{                   
                    background: var(--info);                   
                }
                &:before{
                    content:"Pending";              
                    color: var(--info);

                }
            }
        }
        &.canceled-invoice
        {
            .invoice-status{
                border:1px solid var(--danger);
                color:var(--danger);               
                &:after{                   
                    background: var(--danger);                   
                }
                &:before{
                    content:"Canceled";              
                    color: var(--danger);

                }
            }
        }         

    }

}



/* blockquote */

.blockquote:before {
    content: open-quote;
    position: absolute;
    left: 8px;
    font-size: 80px;
    color: #fff;
    top: 27px;
}

/* Ecommerce */
.caption-bg{
    background-color:  rgba(0, 0, 0, 0.70);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: all 0.85s;

}

/* Line Height Class */
.line-height-1
{
    line-height:1;
}
.line-height-21
{
    line-height: 21px;
}
/* Cryptocurrency Dashboard */
.min-height-auto
{
    min-height: inherit !important;
}

/* Analyitc Dashboard */
.font-size-60{
    font-size: 60px;
}
.shadow-circle
{
    box-shadow: 0 1px 20px 1px var(--primarycolor);
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
}

.white-circle
{
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    line-height: 29px;
    font-size: 12px;
    border-radius: 25px;
    display: block;
    text-align: center;
    &:hover{
        background: #fff;
        color:var(--primarycolor);
    }
}

/*********** Footer ************/
.site-footer {
    text-align: center;
    margin-top:20px;
    padding: 15px 25px;
    background-color:var(--mainbackground);   
    border-top: 1px solid var(--bordercolor);
    text-transform: uppercase;
}

/************** Covid Dash Borad **************/
.covid
{
    background: url(/images/covid.jpg);
    background-size: cover;
    margin-top: 20px;
    border-radius: 15px;
    background-position: center;
}